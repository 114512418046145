//import $ from 'jquery';

import $ from 'jquery';
// export for others scripts to use

window.$ = window.jQuery = $;
import "bootstrap"

//NO FUNCIONA EL CSS DE NODE_MODULES
//import "bootstrap/dist/css/bootstrap.min.css"
import "../css/bootstrap.min.css"

import "jquery-ui/ui/widgets/draggable"; 
import "jquery-ui/ui/widgets/resizable";
import "jquery-ui/ui/widgets/dialog";
import "jquery-ui/ui/widgets/tabs";
import "jquery-ui/ui/widgets/button";
import "jquery-ui/ui/position";
import "jquery-ui/ui/widgets/menu";

import "jquery-ui/themes/base/all.css";

import "../css/mezzanine.css";
import "../css/style.css";
import "../css/meteogrid.css";

import '@fortawesome/fontawesome-free/css/all.min.css';

import Cookies from 'js-cookie';

function global_init(){
const csrftoken = Cookies.get('csrftoken');
function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
    beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
    }
});
$(document).ajaxStart(function () {
    $('body').addClass('wait');
}).ajaxComplete(function () {
    $('body').removeClass('wait');
});
$(function () {
    $('.draggable-panel').draggable();
    $(".draggable-panel").click(function (e) { e.stopPropagation(); });
    $('.resizable-panel').resizable({ handles: "n, e, s, w" });
    $(".resizable-panel").click(function (e) { e.stopPropagation(); });
    $(".alert-dismissable.alert-info").delay(2500).slideUp();
    $(".alert-dismissable.alert-success").delay(2500).slideUp();
});
$(function() {
    if (window.self != window.top) {
      $(document.body).addClass("in-iframe");
    }
});
    // /static/bootstrap-extras.js
$(function()
{
    // Form css tweaks.
    $('.middle input:text, .middle input:password, textarea').not('[class]').addClass('input-xlarge');
    $('.control-group label').addClass('control-label');
    var $dropdowns = $('li.dropdown'); // Specifying the element is faster for older browsers
    /**
     * Mouse events
     *
     * @description Mimic hoverIntent plugin by waiting for the mouse to 'settle' within the target before triggering
     */
    $dropdowns
        .on('mouseover', function() // Mouseenter (used with .hover()) does not trigger when user enters from outside document window
        {
            var $this = $(this);
            if ($this.prop('hoverTimeout'))
            {
                $this.prop('hoverTimeout', clearTimeout($this.prop('hoverTimeout')));
            }
            $this.prop('hoverIntent', setTimeout(function()
            {
                $this.addClass('open');
            }, 250));
        })
        .on('mouseleave', function()
        {
            var $this = $(this);
            if ($this.prop('hoverIntent'))
            {
                $this.prop('hoverIntent', clearTimeout($this.prop('hoverIntent')));
            }
            $this.prop('hoverTimeout', setTimeout(function()
            {
                $this.removeClass('open');
            }, 250));
        });
    /**
     * Touch events
     *
     * @description Support click to open if we're dealing with a touchscreen
     */
    if ('ontouchstart' in document.documentElement)
    {
        $dropdowns.each(function()
        {
            var $this = $(this);
            this.addEventListener('touchstart', function(e)
            {
                if (e.touches.length === 1)
                {
                    // Prevent touch events within dropdown bubbling down to document
                    e.stopPropagation();
                    // Toggle hover
                    if (!$this.hasClass('open'))
                    {
                        // Prevent link on first touch
                        if (e.target === this || e.target.parentNode === this)
                        {
                            e.preventDefault();
                        }
                        // Hide other open dropdowns
                        $dropdowns.removeClass('open');
                        $this.addClass('open');

                        // Hide dropdown on touch outside
                        document.addEventListener('touchstart', closeDropdown = function(e)
                        {
                            e.stopPropagation();

                            $this.removeClass('open');
                            document.removeEventListener('touchstart', closeDropdown);
                        });
                    }
                }
            }, false);
        });
    }
});

}
function cargarCSS(){
    const fileref=document.createElement("link");
    fileref.setAttribute("rel", "stylesheet");
    fileref.setAttribute("type", "text/css");
    fileref.setAttribute("href", window.Urls.iconos_css());
    document.getElementsByTagName("head")[0].appendChild(fileref)
  }
  function getDTMessages(){
    return {
        "sProcessing":     gettext("Procesando..."),
        "sLengthMenu":     gettext("Mostrar _MENU_ registros"),
        "sZeroRecords":    gettext("No se encontraron resultados"),
        "sEmptyTable":     gettext("Ningún dato disponible en esta tabla"),
        "sInfo":           gettext("Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros"),
        "sInfoEmpty":      gettext("Mostrando registros del 0 al 0 de un total de 0 registros"),
        "sInfoFiltered":   gettext("(filtrado de un total de _MAX_ registros)"),
        "sInfoPostFix":    "",
        "sSearch":         gettext("Buscar:"),
        "sUrl":            "",
        "sInfoThousands":  ",",
        "sLoadingRecords": gettext("Cargando..."),
        "oPaginate": {
            "sFirst":    gettext("Primero"),
            "sLast":     gettext("Último"),
            "sNext":     gettext("Siguiente"),
            "sPrevious": gettext("Anterior")
        },
        "oAria": {
            "sSortAscending":  gettext(": Activar para ordenar la columna de manera ascendente"),
            "sSortDescending": gettext(": Activar para ordenar la columna de manera descendente")
    }
  }
}
function getFormData(form) {
    const values = form.serializeArray();
    for (const field of Object.values(values)) {
      const input = form.find(`input[name="${field.name}"][type="datetime-local"]`);
      if (input.length)
        field.value = moment(field.value).toJSON();
    }
    return $.param(values);
  }
  
  /**
 * @param String name
 * @return String
 */
function getParameterByName(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  
export { $, global_init, cargarCSS, getDTMessages, getFormData, getParameterByName };